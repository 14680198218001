import React, { useEffect } from 'react';
import store from '../../store';
import DemoChart from '../../components/DemoChart/DemoChart';

function DemoChartView() {
  const startUpdate = store(s => s.startUpdate);
  const dataLoaded = store(s => s.dataLoaded);
  const seriesData = store(s => s.seriesData);
  const lastData = store(s => s.lastData);

  useEffect(() => {
    startUpdate();
  }, [startUpdate])

  return (
    <div>
      {dataLoaded &&
        <DemoChart seriesData={seriesData} lastData={lastData} />
      }
    </div>
  );
}

export default DemoChartView;