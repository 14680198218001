import { DateTime } from 'luxon';


export default function getMarketDates(fn) {
  const now = new Date(),
        dayOfWeek = now.getDay();

  if (dayOfWeek >= 1 && dayOfWeek <= 5) {
    // const year = now.getFullYear();
    // const month = now.getMonth();
    // const day = now.getDate();

    const marketStart = DateTime.fromFormat(`09:30:00`, 'HH:mm:ss', { zone: 'America/New_York' }).toMillis();
    const marketEnd = DateTime.fromFormat(`16:15:00`, 'HH:mm:ss', { zone: 'America/New_York' }).toMillis();

    // const marketStart = new Date(year, month, day, 9, 30, 0, 0);
    // const marketEnd = new Date(year, month, day, 16, 34, 14, 0);
    // const marketActive = marketStart &&
    //   now.getTime() >= marketStart.getTime() &&
    //   now.getTime() <= marketEnd.getTime();

    const marketActive = marketStart &&
      now.getTime() >= marketStart &&
      now.getTime() <= marketEnd;

    return {
      now,
      marketStart,
      marketEnd,
      marketActive
    };
  } else {
    return {
      now,
      marketStart: null,
      marketEnd: null,
      marketActive: false
    };
  }
}
