import { create } from 'zustand';
import config from '../config.json';
import { devtools } from 'zustand/middleware';
import axios from 'axios';
import log from 'loglevel';
import { find } from 'lodash';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { format } from 'date-fns';
import getMarketDates from '../lib/market-dates';
import { DateTime } from 'luxon';


const store = create(devtools((set, get) => ({
  config,
  dataLoaded: false,
  seriesData: { },
  lastData: { },
  strikeData: [],
  bookmapFeed: undefined,
  duration: config.defaultDuration,
  dte: config.defaultExpiration,
  wss: undefined,
  reset: false,
  decayCurve: undefined,
  decayCurveRaw: undefined,
  emInitialValue: undefined,
  currentDate: undefined,
  availableDates: [],
  marketActive: false,
  isUpdating: false,

  updateReset: async reset => {
    const { updateDates, updateDecay } = get();
    log.debug(`[store.updateReset]`);

    await updateDates();
    await updateDecay();
    set({ reset });
  },

  syncDurationFromLocalStorage: () => {
    const duration = localStorage.getItem('duration');
    const dte = localStorage.getItem('dte');

    if (duration) {
      set({ duration });
    }

    if (dte) {
      set({ dte });
    }
  },

  updateDte: (dte, init = false) => {
    const { wss } = get();

    if (!wss)
      return

    const data = JSON.stringify({ type: 'dte', dte });

    wss.send(data);

    if (!init) {
      set({ dte, reset: true });
      localStorage.setItem('dte', dte);   
    }
  },

  updateDuration: (duration, init = false) => {
    const { wss } = get();

    if (!wss)
      return

    const data = JSON.stringify({ type: 'duration', duration });

    wss.send(data);

    if (!init) {
      set({ duration, reset: true });
      localStorage.setItem('duration', duration);   
    }
  },

  updateSeriesData: async() => {
    const { config: { apiUri }, duration, dte, currentDate } = get();

    set({ isUpdating: true });

    let date = format(new Date(), 'yyyy-MM-dd');

    if (currentDate !== 'live') {
      date = currentDate;
    }

    const dayStart = DateTime.fromFormat(`${date} 09:32:00`, 'yyyy-MM-dd HH:mm:ss', { zone: 'America/New_York' }).toMillis();
    const dayEnd = DateTime.fromFormat(`${date} 15:59:50`, 'yyyy-MM-dd HH:mm:ss', { zone: 'America/New_York' }).toMillis();

    const url = `${apiUri}/decay?start=${dayStart}&end=${dayEnd}&duration=${duration}&dte=${dte}`
    const { data } = await axios.request({ url });

    set({
      seriesData: {
        price: formatSeries(data.price),
        // otmCallValue: formatSeries(data.otmCallValue),
        otmCallVolume: formatSeries(data.otmCallVolume),
        // otmPutValue: formatSeries(data.otmPutValue),
        otmPutVolume: formatSeries(data.otmPutVolume),
        atmStrike: formatSeries(data.atmStrike),
        expectedMove: formatSeries(data.expectedMove, 10),
        expectedMove2: formatSeries(data.expectedMove2, 10),
        // otmPriceAsk: formatSeries(data.otmPriceAsk),
        // otmPriceAskWithATM: formatSeries(data.otmPriceAskWithATM),
        // otmPriceBid: formatSeries(data.otmPriceBid),
        // otmPriceMark: formatSeries(data.otmPriceMark),
        otmPriceLast: formatSeries(data.otmPriceLast, 10),
        // atmGamma: formatSeries(data.atmGamma.map(([t,v]) => ([t,v*10]))),
        // atmVolatility: formatSeries(data.atmVolatility.map(([t,v]) => ([t,v*10]))),
        // atmNetVolume: formatSeries(data.atmNetVolume),
        otmCallValueWithSkew: formatSeries(data.otmCallValueWithSkew),
        otmPutValueWithSkew: formatSeries(data.otmPutValueWithSkew),
        // wOtmCallPrice: formatSeries(data.wOtmCallPrice),
        // wOtmPutPrice: formatSeries(data.wOtmPutPrice),
        // wOtmPrice: formatSeries(data.wOtmPrice),
      }
    });

    set({ isUpdating: false })
  },

  startUpdate: async (reset = false) => {
    const { config: { wssUri }, updateSeriesData, updateDecay, updateDates, updateDuration, updateDte, wss: existWss, syncDurationFromLocalStorage } = get();

    syncDurationFromLocalStorage();

    await updateDates();
    await updateSeriesData();
    await updateDecay();

    let wss;

    let outObj = {
      dataLoaded: true
    }

    if (!reset) {
      wss = new ReconnectingWebSocket(`${wssUri}/live`);
      // wss = new ReconnectingWebSocket('ws://localhost:3000/live');
      outObj.wss = wss;
    } else if (existWss) {
      wss = existWss;
      outObj.wss = existWss;
    }

    set(outObj);

    if (!reset) {
      let last = +DateTime.now().setZone('utc', { keepLocalTime: true }).toFormat('X');

      wss.onmessage = async (d) => {
        const { decayCurveRaw, currentDate: cd, duration } = get();

        if (cd !== 'live')
          return;
        
        // debugging
        const startTime = new Date().getTime();

        const updatedData = JSON.parse(d.data);

        let date;

        if (duration === '1s') {
          date = DateTime
            .fromISO(updatedData.timestamp, { zone: 'utc' })
            .setZone('utc', { keepLocalTime: true });
        } else {
          date = DateTime
            .fromMillis(+updatedData.timestamp, { zone: 'utc' })
            .setZone('utc', { keepLocalTime: true });
        }

        const time = +date.toFormat('X')

        // const dd = new Date(updatedData.timestamp);
        // const time = Date.UTC(dd.getFullYear(), dd.getMonth(), dd.getDate(), dd.getHours(), dd.getMinutes(), dd.getSeconds(), dd.getMilliseconds()) / 1000;

        const decayPoint = find(
          decayCurveRaw,
          {
            h: +date.hour,
            m: +date.minute,
            s: +date.second
          }
        );

        if (time <= Math.ceil(last)) {
          log.warn(`[wss update] time mismatch ${time} <= ${last} (${(time - last).toFixed(2)}s); ignoring update`)
          last = time;
          return
        }

        let lastData = {
            price: { time, value: updatedData.price },
            // otmCallValue: { time, value: updatedData.otmCallValue },
            otmCallVolume: { time, value: updatedData.otmCallVolume },
            // otmPutValue: { time, value: updatedData.otmPutValue },
            otmPutVolume: { time, value: updatedData.otmPutVolume },
            atmStrike: { time, value: updatedData.atmStrike },
            expectedMove: { time, value: updatedData.expectedMove },
            expectedMove2: { time, value: updatedData.expectedMove2 },
            // otmPriceAsk: { time, value: updatedData.otmPriceAsk },
            // otmPriceAskWithATM: { time, value: updatedData.otmPriceAskWithATM },
            // otmPriceBid: { time, value: updatedData.otmPriceBid },
            // otmPriceMark: { time, value: updatedData.otmPriceMark },
            otmPriceLast: { time, value: updatedData.otmPriceLast },
            // atmGamma: { time, value: updatedData.atmGamma * 10 },
            // atmVolatility: { time, value: updatedData.atmVolatility * 10 },
            // atmNetVolume: { time, value: updatedData.atmNetVolume },
            otmCallValueWithSkew: { time, value: updatedData.otmCallValueWithSkew },
            otmPutValueWithSkew: { time, value: updatedData.otmPutValueWithSkew },
            // wOtmCallPrice: { time, value: updatedData.wOtmCallPrice },
            // wOtmPutPrice: { time, value: updatedData.wOtmPutPrice },
            // wOtmPrice: { time, value: updatedData.wOtmPrice },
        };

        if (decayPoint) {
          const { emInitialValue } = get();

          const decayPointValue = decayPoint.avg + (decayPoint.avg * emInitialValue);
          lastData.decay = { time, value: decayPointValue };
        }

        last = time;

        log.warn(`[wss update] lastData update took ${new Date().getTime() - startTime} ms`);

        set({ lastData })
      };

      wss.onopen = async (msg) => {
        const { duration, dte } = get();

        log.info(`[store/websocket] open`);
        
        updateDuration(duration, true);
        updateDte(dte, true);
      };

      wss.onerror = (msg) => {
        log.error(`[store/websocket] error`, msg);
      };

      wss.onclose = (msg) => {
        log.warn(`[store/websocket] close`, msg);
      };
    }
  },

  updateStrike: async timestamp => {
    const { config: { apiUri } } = get();
    const { data: { strikes, underlying, atmStrike } } = await axios.get(`${apiUri}/strike?timestamp=${timestamp}`)
    let times = {};

    const maxStrike = atmStrike + 10;
    const minStrike = atmStrike - 10;

    strikes.forEach(s => {
      if (!times[s.created])
        times[s.created] = {};

      if (+s.strike_price <= minStrike || +s.strike_price >= maxStrike) {
        return
      } else {

      }

      if (!times[s.created][s.strike_price]) {
        times[s.created][s.strike_price] = {
          strikes: [],
          underlying,
          atmStrike
        }
      }

      if (s.type === 'call') {
        times[s.created][s.strike_price].call = s
      }
      else if (s.type === 'put') {
        times[s.created][s.strike_price].put = s
      }
    })

    set({ strikeData: { strikes: times, atmStrike, underlying }})
  },

  updateBookmap: async () => {
    const { config: { apiUri } } = get();
    const { data: bookmapFeed  } = await axios.get(`${apiUri}/bm`);
    set({ bookmapFeed });
  },

  updateDecay: async() => {
    const { duration, currentDate, seriesData, dte, config: { apiUri } } = get();

    set({ isUpdating: true });

    const projectionLine = seriesData?.expectedMove2?.length ? seriesData.expectedMove2 : seriesData.expectedMove;

    if (projectionLine.length) {
      const date = format(new Date(), 'yyyy-MM-dd')
      const { data: { decayCurve } } = await axios.get(`${apiUri}/decay/historical?date=${currentDate !== 'live' ? currentDate : date}&duration=${duration}&expiration=${dte}`);

      let alignedDecay = [];

      console.log(`series: ${seriesData.price.length} | decay: ${decayCurve.length}`)

      const start = new Date().getTime();

      const { value: emInitialValue } = projectionLine[0];

      projectionLine.forEach(({ time: time_, utc, value }, i) => {
        const tt = DateTime
                    .fromSeconds(utc)
                    .setZone('utc', { keepLocalTime: false });
                    //.fromSeconds(utc, { zone: 'utc' })
                    // .fromSeconds(utc, { zone: 'utc' })

        const de = find(decayCurve, { h: +tt.hour, m: +tt.minute, s: +tt.second });
        // console.log({ h: +tt.hour, m: +tt.minute, s: +tt.second });

        if (de?.avg) {
          // alignedDecay.push({
          //   time: time_,
          //   value: de.avg //+ lastEmBar.value
          // });

          alignedDecay.push({
            time: time_,
            value: de.avg + (de.avg * emInitialValue),
          });
        }
      });

      console.log(`[updateDecay] took ${(new Date().getTime() - start).toFixed(3)}ms`);

      set({ decayCurve: alignedDecay, decayCurveRaw: decayCurve, isUpdating: false, emInitialValue });
    }
  },

  updateCurrentDate: currentDate => {
    log.debug(`[store.updateCurrentDate] ${currentDate}`);
    set({ currentDate, reset: true });
  },

  updateDates: async() => {
    // @TODO: needs to update for those that never refresh view

    set({ isUpdating: true });

    const { duration, dte, config: { apiUri } } = get();
    const { marketActive } = getMarketDates();

    const { data: { dates} } = await axios.get(`${apiUri}/decay/historical/dates?expiration=${dte}&duration=${duration}`);
    let availableDates = [];
    
    if (marketActive && dte === '0') {
      availableDates = [].concat('live').concat(dates.reverse());
    } else {
      availableDates = [].concat(dates.reverse());
    }

    set({ availableDates, currentDate: marketActive ? 'live' : availableDates[0], isUpdating: false });
  } 

}))
);

const formatSeries = (series, smoothing = 1000) => {
  const startTime = new Date().getTime();

  let last = undefined;
  let out = []
  let removedCnt = 0;

  const totalValue = series.reduce((p, [t, value]) => { return p + value }, 0)
  const seriesAvg = totalValue/series.length;

  series.forEach(([t, value], i) => {
    // const d = new Date(t);
    // const time = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;

    const core = DateTime.fromMillis(t); 
    const tt = core.setZone('utc', { keepLocalTime: true });
    const time = +tt.toFormat('X');

    //const ttUtc = DateTime.fromMillis(t).setZone('utc', { keepLocalTime: false });
    const ttUtc = core.setZone('utc', { keepLocalTime: false });
    const utc = +ttUtc.toFormat('X');


    if (last) {
      const diff = value - last.value;
      const diffPerc = Math.abs((diff / seriesAvg) * 100);

      if (diffPerc < smoothing) {
        out.push({ value, time, utc })
      } else {
        removedCnt++;
      }
    } else {
      out.push({ value, time, utc })
    }

    last = { time, value, utc };
  });

  console.log(`[formatSeries] ${new Date().getTime() - startTime}ms | smoothed: ${removedCnt} | ${seriesAvg/.5}`)

  return out;
};

// const formatSeriesOld = series => series.map(([t, v]) => {
//   const value = v;

//   const d = new Date(t);
//   const time = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()) / 1000;

//   return {
//     value,
//     time
//   };
// });

export default store;