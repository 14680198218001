import React from 'react';
import store from '../../store';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { Flex } from '@chakra-ui/react'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


const BookmapPlayer = () => {
  const bookmapFeed = store(s => s.bookmapFeed);
  let bookmapFeedId = undefined;

  if (bookmapFeed) {
    bookmapFeedId = bookmapFeed.link.split('?')[1].split('=')[1];
  }

  return (
    <Flex flexWrap='wrap' alignItems='center' justifyContent='center' width='100vw' height='100vh'>
      {bookmapFeedId &&
        <LiteYouTubeEmbed
          playerClass='bm-embed'
          adNetwork={false}
          id={bookmapFeedId} />
      }
    </Flex>
  )

};


export default BookmapPlayer;