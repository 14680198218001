import React, { useEffect, useState } from 'react';
import { Icon, Flex, Button, Select, Spinner, Text } from '@chakra-ui/react'
//import { GoPulse } from 'react-icons/go';
import { HiBell } from 'react-icons/hi';
//import log from 'loglevel';
import { createChart, LineStyle, CrosshairMode } from 'lightweight-charts';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import cn from 'classnames';
import store from '../../store';
//import getMarketDates from '../../lib/market-dates';

// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// })

let chart;

let DURATIONS = [
  { label: '1s' },
  { label: '5s' },
  { label: '10s' },
  { label: '30s' },
  { label: '1m' },
  { label: '3m' },
  { label: '5m' },
  { label: '1d' },
  { label: '1w' },
  { label: '1M' }
]

let DTES = [
  { label: 0 },
  { label: 1 },
  { label: 2 }
]

const SERIES_DEF = [
  // price
  {
    key: 'price',
    type: 'area',
    defaultVisible: true,
    scale: 2,
    prefix: '$',
    chartOptions: {
      title: 'SPX',
      topColor: 'rgba(21, 176, 215, 0.2)',
      lineColor: 'rgba(21, 176, 215, 0.3)',
      bottomColor: 'rgba(21, 176, 215, 0)',
      lineWidth: 1,
      priceScaleId: 3, 
    }
  },

  // atmStrike
  {
    key: 'atmStrike',
    type: 'line',
    scale: 0,
    prefix: '$',
    chartOptions: {
      title: 'ATM',
      lineWidth: 2,
      priceScaleId: 3,
      color: '#d9d8d9',
      lineStyle: LineStyle.Dashed
    }
  },

  // expectedMove2
  {
    key: 'expectedMove2',
    type: 'line',
    scale: 0,
    chartOptions: {
      title: 'EM',
      color: 'rgb(218, 165, 32)',
      lineWidth: 2,
      priceScaleId: 'left'
    }
  },

  // expectedMove
  {
    key: 'expectedMove',
    type: 'line',
    scale: 0,
    chartOptions: {
      title: 'Tasty EM',
      color: 'rgb(255, 234, 0)',
      lineWidth: 2,
      priceScaleId: 'left'
    }
  },

  // otmPriceAskWithATM
  // {
  //   key: 'otmPriceAskWithATM',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$',
  //   chartOptions: {
  //     title: 'OTM Value (on Ask)',
  //     lineWidth: 2,
  //     priceScaleId: 'right',
  //     color: '#FF69B4'
  //   }
  // },

  // otmPriceBid
  // {
  //   key: 'otmPriceBid',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$',
  //   chartOptions: {
  //     title: 'OTM Value (on Bid)',
  //     lineWidth: 2,
  //     priceScaleId: 'right',
  //     color: '#663a82'
  //   }
  // },

  // otmPriceMark 
  // {
  //   key: 'otmPriceMark',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$',
  //   chartOptions: {
  //     title: 'OTM Value (on Mark)',
  //     lineWidth: 2,
  //     priceScaleId: 'right',
  //     color: '#68507b'
  //   }
  // },

  // otmPriceLast
  {
    key: 'otmPriceLast',
    type: 'line',
    scale: 2,
    prefix: '$',
    chartOptions: {
      title: 'OTM Value',
      lineWidth: 2,
      priceScaleId: 'right',
      color: '#b491c8'
    }
  },

  // otmCallValueWithSkew
  {
    key: 'otmCallValueWithSkew',
    type: 'line',
    scale: 2,
    prefix: '$',
    chartOptions: {
      title: 'Call Value',
      lineWidth: 2,
      priceScaleId: 'right',
      color: '#32CD32'
    }
  },

  // otmPutValueWithSkew
  {
    key: 'otmPutValueWithSkew',
    type: 'line',
    scale: 2,
    prefix: '$',
    chartOptions: {
      title: 'Put Value',
      color: '#EE4B2B',
      lineWidth: 2,
      priceScaleId: 'right'
    }
  },

  // otmCallVolume
  {
    key: 'otmCallVolume',
    type: 'histogram',
    scale: 0,
    chartOptions: {
      title: 'Call Volume',
      color: '#066939',
      priceFormat: {
        type: 'volume'
      },
      priceScaleId: 'volume',
      scaleMargins: {
        // top: 0.3,
        // bottom: 0.5
      }
    }
  },

  // otmPutVolume
  {
    key: 'otmPutVolume',
    type: 'histogram',
    scale: 0,
    chartOptions: {
      title: 'Put Volume',
      color: '#6d0f0f',
      priceFormat: {
        type: 'volume'
      },
      priceScaleId: 'volume',
      scaleMargins: {
        // top: 0.3,
        // bottom: 0.5
      }
    }
  },


  // otmPriceAsk
  // {
  //   key: 'otmPriceAsk',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$',
  //   chartOptions: {
  //     title: 'OTM Value (Old Method)',
  //     lineWidth: 2,
  //     priceScaleId: 'right',
  //     color: '#FF00FF'
  //   }
  // },

  // otmCallValue
  // {
  //   key: 'otmCallValue',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$',
  //   chartOptions: {
  //     title: 'OTM Value (Calls)',
  //     color: '#066939',
  //     lineWidth: 2,
  //     priceScaleId: 'right',
  //   }
  // },

  // otmPutValue
  // {
  //   key: 'otmPutValue',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$', 
  //   chartOptions: {
  //     title: 'OTM Value (Puts)',
  //     color: '#6d0f0f',
  //     lineWidth: 2,
  //     priceScaleId: 'right'
  //   }
  // },



  // atmGamma
  // {
  //   key: 'atmGamma',
  //   type: 'line',
  //   scale: 4,
  //   chartOptions: {
  //     title: 'ATM Gamma',
  //     lineWidth: 2,
  //     priceScaleId: 'right',
  //     color: '#cab707'
  //   }
  // },

  // atmVolatility
  // {
  //   key: 'atmVolatility',
  //   type: 'line',
  //   scale: 4,
  //   chartOptions: {
  //     title: 'ATM Volatility',
  //     lineWidth: 2,
  //     priceScaleId: 'right',
  //     color: '#ab708a'
  //   }
  // },

  // atmNetVolume
  // {
  //   key: 'atmNetVolume',
  //   type: 'line',
  //   scale: 4,
  //   chartOptions: {
  //     title: 'ATM Net Volume',
  //     lineWidth: 2,
  //     priceScaleId: 'right',
  //     color: '#ab708a'
  //   }
  // },

  // wOtmCallPrice
  // {
  //   key: 'wOtmCallPrice',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$',
  //   chartOptions: {
  //     title: 'wOTM Value (Calls)',
  //     color: '#454B1B',
  //     lineWidth: 2,
  //     priceScaleId: 'right'
  //   }
  // },

  // wOtmPutPrice
  // {
  //   key: 'wOtmPutPrice',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$',
  //   chartOptions: {
  //     title: 'wOTM Value (Puts)',
  //     color: '#880808',
  //     lineWidth: 2,
  //     priceScaleId: 'right'
  //   }
  // },

  // wOtmPrice
  // {
  //   key: 'wOtmPrice',
  //   type: 'line',
  //   scale: 2,
  //   prefix: '$',
  //   chartOptions: {
  //     title: 'wOTM Value',
  //     color: '#FFD700',
  //     lineWidth: 2,
  //     priceScaleId: 'right'
  //   }
  // }
]

// const TOOLTIP_DEFAULT = {
//   display: 'none',
//   time: undefined,
//   prices: []
// }

const getStoredToggleState = () => {
  const seriesToggles = localStorage.getItem('seriesToggles');
  let finalToggles = {}

  try {
    finalToggles = JSON.parse(seriesToggles) || {};
  } catch (err) {
    console.warn(`[getStoredToggleState] unable to parse/set toggles`, err);
  }

  
  SERIES_DEF.forEach(s => {
    if (finalToggles[s.key] === undefined) {
      finalToggles[s.key] = s.defaultVisible !== undefined ? s.defaultVisible : false;
    }
  });

  return finalToggles;
}

const DemoChart = ({ seriesData, lastData }) => {
  const chartRef = React.useRef();
  //const tooltipRef = React.useRef();
  const [series, setSeries] = useState([]);
  const [decaySeries, setDecaySeries] = useState([]);
  const [seriesToggle, setSeriesToggle] = useState(getStoredToggleState(series));
  const [decayToggle, setDecayToggle] = useState(seriesToggle.expectedMove2 || seriesToggle.expectedMove);
  //const [tooltipData, setTooltipData] = useState(TOOLTIP_DEFAULT);
  //const updateStrike = store(s => s.updateStrike);
  // const strikeData = store(s => s.strikeData);
  const duration = store(s => s.duration);
  const dte = store(s => s.dte);
  const reset = store(s => s.reset);
  const updateDuration = store(s => s.updateDuration);
  const updateDte = store(s => s.updateDte);
  const updateReset = store(s => s.updateReset);
  const updateSeriesData = store(s => s.updateSeriesData);
  const updateDecay = store(s => s.updateDecay);
  const availableDates = store(s => s.availableDates);
  const currentDate = store(s => s.currentDate);
  const isUpdating = store(s => s.isUpdating);
  const updateCurrentDate = store(s => s.updateCurrentDate);

  const toggleSeries = i => () => {
    const { series: series_, def } = series[i];
    const { visible } = series_.options();

    series_.applyOptions({
      visible: !visible 
    });
    
    const updatedToggles = {
      ...seriesToggle,
      [def.key]: !visible
    };

    setSeriesToggle(updatedToggles);

    localStorage.setItem('seriesToggles', JSON.stringify(updatedToggles));

    if (def.key === 'expectedMove2' || def.key === 'expectedMove') {
      if ((updatedToggles.expectedMove2 || updatedToggles.expectedMove) && !decayToggle) {
        decaySeries.applyOptions({ visible: true });
        setDecayToggle(true);
      } else {
        decaySeries.applyOptions({ visible: false });
        setDecayToggle(false);
      }
    }
  }

  const toggleDuration = i => () => updateDuration(i);
  
  const switchDate = async ev => {
    updateCurrentDate(ev.target.value);
  }

  const toggleDte = i => () => updateDte(i);

  //const disableTooltip = () => setTooltipData(TOOLTIP_DEFAULT);

  const generateSeries = (def, addData = false) => {
    const type = def.type.charAt(0).toUpperCase() + def.type.slice(1);
    const { seriesData: seriesData_ } = store.getState();
    
    const series_ = chart[`add${type}Series`]({
      ...def.chartOptions || {},
      title: '',
      visible: seriesToggle[def.key]
    });

    if (addData) {
      // log.debug(`[DemoChart]  generateSeries for ${def.key}: ${seriesData[def.key].length} | sData: ${sData[def.key].length}`);
      series_.setData(seriesData_[def.key])
    }

    return series_;
  }

  const generateDecaySeries = () => {
    const { decayCurve } = store.getState();

    if (decayCurve && decayCurve.length) {
      const series_ = chart.addLineSeries({
        title: '',
        color: '#808080',
        lineWidth: 2,
        //priceScaleId: 'left',
        priceScaleId: 'left',
        lineStyle: LineStyle.Dashed,
        visible: decayToggle
      });

      series_.setData(decayCurve);

      return series_;
    }
  }

  const doCreateChart = () => {
    chart = createChart(chartRef.current, {
      width: chartRef.current.offsetWidth,
      height: chartRef.current.offsetHeight,
      alignLabels: true,
      timeScale: {
        rightOffset: 12,
        barSpacing: 1,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: false,
        rightBarStaysOnScroll: false,
        borderVisible: false,
        borderColor: "#fff000",
        visible: true,
        timeVisible: true,
        secondsVisible: true
      },
      leftPriceScale: {
        autoScale: true,
        borderVisible: false,
        visible: true,
        // scaleMargins: {
        //   top: 0,
        //   bottom: 0,
        // },
      },
      rightPriceScale: {
        autoScale: true,
        borderVisible: false,
        // scaleMargins: {
        //   top: 0,
        //   bottom: 0,
        // },
      },
      grid: {
        vertLines: {
          color: 'rgba(42, 46, 57, 0)',
        },
        horzLines: {
          color: 'rgba(42, 46, 57, 0.6)',
        },
      },
      layout: {
        background: {
          color: '#000000'
        },
        textColor: '#d1d4dc',
      },
      priceScale: {
        autoScale: true,
      },
      crosshair: {
        mode: CrosshairMode.Magnet,
      },
    });

    const series_ = SERIES_DEF.map(def => ({ def, series: generateSeries(def, true) }));
    setSeries(series_);

    const decaySeries_ = generateDecaySeries();
    setDecaySeries(decaySeries_);

    chart.timeScale().fitContent();

    // chart.subscribeCrosshairMove(
    //   param => {
    //     if (
    //       param.point === undefined ||
    //       !param.time ||
    //       param.point.x < 0 ||
    //       param.point.x > chartRef.clientWidth ||
    //       param.point.y < 0 ||
    //       param.point.y > chartRef.clientHeight
    //     ) {
    //       disableTooltip();
    //     } else {
    //       let prices = [];

    //       series_.forEach((s, i) => {
    //         const value = param.seriesPrices.get(s.series);
    //         const { visible } = s.series.options();

    //         if (value && visible) {
    //           prices.push({
    //             label: s.def.chartOptions.title,
    //             scale: s.def.scale,
    //             prefix: s.def.prefix || '',
    //             color: s.def.chartOptions.color,
    //             value
    //           })
    //         }
    //       });

    //       // @TODO - TF?
    //       const time = utcToZonedTime(param.time * 1000, 'UTC');

    //       setTooltipData({
    //         display: 'block',
    //         left: param.point.x + 10,
    //         top: param.point.y + 10,
    //         time,
    //         prices
    //       });
    //     }
    //   }
    // );

    // chart.subscribeClick(async param => {
    //   const ts = utcToZonedTime(param.time * 1000, 'UTC');
    //   await updateStrike(ts.getTime())
    // })

    return () => {
      console.log(`[unsubscribeCrosshairMove]`)
      chart.unsubscribeCrosshairMove();
    }
  }

  React.useEffect(() => {
    doCreateChart();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reset) {
      // series.forEach((s, i) => {
      //   console.log(`[reset] setData[] ${s.def.key}`)
      //   // s.series.setData([]);
      // });

      chart.remove();

      (async function () {
        await updateSeriesData();
        await updateDecay();
        doCreateChart();
        updateReset(false);
        // setTimeout(() => {
        //   chart.timeScale().fitContent();
        // }, 10);
      })()
    }
  }, [reset])

  React.useEffect(() => {
    if (!lastData)
      return;

    series.forEach(series_ => {
      const { key } = series_.def;
      const s = series.filter(s => s.def.key === key)[0]

      if (s && lastData[key]) {
        s.series.update(lastData[key]);
      }
    })
    
    if (lastData.price) {
      document.title = `[SPX: $${lastData.price.value.toFixed(2)}] ringthebell`;
    }

    if (lastData.decay) {
      decaySeries.update(lastData.decay);
    }
  }, [lastData]);


  React.useEffect(() => {
    const handler = () => {
      console.log(`[resizeHandler] resizing: ${chartRef.current.offsetWidth}x${chartRef.current.offsetHeight}`)
      chart.resize(chartRef.current.offsetWidth, chartRef.current.offsetHeight);
    };

    window.addEventListener('resize', handler);
    return () => {
      console.log(`[resizeHandler] removing`)
      window.removeEventListener('resize', handler);
    };
  }, []);

  // const displayPrices = () => (
  //   <div>
  //     {tooltipData.prices.map((tt, i) =>
  //       <div className='tooltip-prices' style={{ color: '#fff' }} key={`tooltip-${i}`}>
  //         <strong>{tt.label}:</strong> &nbsp;
  //         {tt.prefix ? tt.prefix : ''}{tt.value.toFixed(tt.scale)}
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <Flex w={['95vw']} flexWrap='wrap' justifyContent='center' ml={10} mr={10} mt={1} mb={1}>
      <Flex w='100%' flexWrap='wrap' justifyContent='space-between'>

        <Flex flexWrap='wrap'>
          <Icon as={HiBell} fontSize='2xl' mr={1} mt={2} color='green.400' />
          <Text fontSize='xl' mt={1} mb={0} verticalAlign='middle'>
            ring<strong>the</strong>bell
          </Text>
        </Flex>

        {/* <Flex flexWrap='wrap' mt={1}>
          {(currentDate === 'live' && dte === '0') &&
            <Tag colorScheme='green' size='sm' variant='subtle'>
              <Icon as={GoPulse} mr={2} />
              Live
            </Tag>
          }
        </Flex> */}

        <Flex flexWrap='wrap' mt={2}>
          <Flex flexWrap='flex' ml={3} mr={3}>
            {isUpdating &&
              <Spinner size='xs' mt={2} />
            }
          </Flex>

          <Flex flexWrap='wrap'>
            <Select size='xs' colorScheme='teal' defaultValue={currentDate} onChange={switchDate} disabled={isUpdating}>
              {availableDates.map(d => {
                let formattedDate = format(utcToZonedTime(new Date()), 'iii yyyy-MM-dd');

                if (d !== 'live')
                  formattedDate = format(utcToZonedTime(new Date(d)), 'iii yyyy-MM-dd');

                return <option key={`date-${d}`} value={d}>{formattedDate}{(d === 'live' && dte === '0') && ' (live)'}</option>;
              })}
            </Select>
          </Flex>
        </Flex>

        <Flex flexWrap='wrap' mt={2}>
          {DTES.map((s, i) => {
            let isActive = false;

            if (dte === s.label || dte === s.label.toString())
              isActive = true;

            return <Button key={`dte-${s.label}`} mr={1} colorScheme='gray' isActive={isActive} size='xs' onClick={toggleDte(s.label)}>{s.label} DTE</Button>
          })}
        </Flex>

        <Flex width={['100%', '100%', '100%', 'auto']} justifyContent='space-between' flexWrap='wrap' mt={2}>
          {DURATIONS.map((s, i) => {
            let isActive = false;

            if (duration === s.label)
              isActive = true;

            return <Button key={`duration-${s.label}`} mr={1} colorScheme='gray' isActive={isActive} size='xs' onClick={toggleDuration(s.label)}>{s.label}</Button>
          })}
        </Flex>
      </Flex>

      <Flex width='95vw' ref={chartRef} flexWrap='wrap' className='chart' justifyContent='center' mt={2} mb={1}>
          {/* <Flex ref={tooltipRef} className='tooltip' style={{ left: tooltipData.left, top: tooltipData.top, display: tooltipData.display }}>
            <h3>{tooltipData.time && format(tooltipData.time, 'hh:mm:ss a')}</h3>
            {displayPrices()}
          </Flex> */}
      </Flex>

      <Flex width='95vw' flexWrap='wrap' justifyContent='center' mb={1}>
        <div className='toggles'>
        {series.map((s, i) => {
          const co = s.def.chartOptions;

          let classNames = ['toggle'];

          if (seriesToggle[s.def.key])
            classNames.push('active');

          let indicator = '━━';

          if (co.title === 'ATM')
            indicator = '﹘﹘';

          // if (co.title.match('Volume') && co.title !== 'ATM Net Volume')
          //   indicator = '╻╹';

          return (
            <div key={`toggle-${i}`} className={cn(classNames)} onClick={toggleSeries(i)}>
              <span className='indicator' style={{ color: co.color }}> {indicator} </span>
              {co.title}
            </div>
          );
        })}
        </div>
      </Flex>
    </Flex>
  );
}


export default DemoChart;