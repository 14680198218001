import React, { useEffect } from 'react';
import store from '../../store';
import BookmapPlayer from '../../components/BookmapPlayer/BookmapPlayer';


const BookmapPlayerView = () => {
  const updateBookmap = store(s => s.updateBookmap);

  useEffect(() => {
    updateBookmap();
  }, [updateBookmap])

  return (
    <div>
      <BookmapPlayer />
    </div>
  );

};


export default BookmapPlayerView;