import log from 'loglevel';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import useMount from './lib/hooks/use-mount';
import store from './store';
import DemoChartView from './views/DemoChartView/DemoChartView';
import BookmapPlayerView from './views/BookmapPlayerView/BookmapPlayerView';
import { ChakraProvider, Flex, extendTheme } from '@chakra-ui/react'
import ReactGA from 'react-ga4';
import './App.css';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },

  styles: {
    global: props => ({
      body: {
        backgroundColor: 'rgba(0, 0, 0, 255)'
      }
    })
  }
});

ReactGA.initialize('G-2PZ5EV523R');

function App() {
  const logLevel = store(s => s.config.logLevel);

  useMount(() => {
    log.setLevel(logLevel, true);
  });

  return (
    <ChakraProvider theme={theme}>
      <Flex flexWrap='wrap' alignItems='center' justifyContent='center'>
        <Flex mb={0}>
          <Router>
            <Routes>
              <Route path='/' element={<DemoChartView />} />
              <Route path='/preview' element={<Navigate replace to="/" />} />
              <Route path='/bm' element={<BookmapPlayerView />} />
            </Routes>
          </Router>
        </Flex>

        {/* <Flex alignItems='center' fontSize='0.8rem' m={0}>
          &copy; 2022-2023 The Desk
        </Flex> */}
      </Flex>
    </ChakraProvider>
  );
}

export default App;